
import { defineComponent } from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import gbs from '@/services/gbs';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { ConvertDate } from '@/components/converters/date';

export default defineComponent({
  name: 'GbsUploadArticlesPositionView',
  components: {
    BaseIcon,
    BaseTable,
  },
  data: () => ({
    formDataFile: null as any,
    archiveData: [] as any,
  }),
  computed: {
    linkPositionFile() {
      return (link: any) => `${process.env.VUE_APP_URL_FILES}${link.split('\\').join('/')}`
    },
    getLinkForDownloadTemplate() {
      return gbs.getPositionTemplate()
    },
    archiveHeader() {
      return [
        {
          title: 'Дата загрузки',
          content: (item: any) => ConvertDate(item.dateTimeUploaded)
        },
        {
          title: 'Ссылка на скачивание',
          content: (item: any) => item.link
        },
      ]
    }
  },
  methods: {
    parseTable(e: any) {
      let file = e.target.files;
      const formData = new FormData();
      formData.append('file', file[0]);
      this.formDataFile = formData
    },
    async sendFile() {
      try {
        await gbs.uploadPricePosition(this.formDataFile)
        this.formDataFile = null
        const input = document.querySelector('input[type=file]') as any
        input.value = '';
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
        this.$emit('updateArchiveArticles')
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    async setArchivePositions() {
      this.archiveData = await gbs.searchPosition()
    }
  },
  mounted() {
    this.setArchivePositions()
  }
})
