import {httpClient} from "@/httpClient";
import axios from 'axios';

function stealArticleInfo(formData: any) {
  return httpClient.post(`/gbs/${localStorage.getItem('cid')}/steal/excel`, formData).then(response => response.data)
}

function stealArticles(articles: any) {
  return httpClient.post(`/gbs/${localStorage.getItem('cid')}/steal/articles`, articles).then(response => response.data)
}

function getRequestById(requestId: any) {
  return httpClient.get(`/gbs/${localStorage.getItem('cid')}/${requestId}`).then(response => response.data)
}

function getStealTemplate() {
  return `${axios.defaults.baseURL}/gbs/${localStorage.getItem('cid')}/steal/excel/template`
}

function getPositionTemplate() {
  return `${axios.defaults.baseURL}/gbs/${localStorage.getItem('cid')}/brands/template`
}

function uploadPricePosition(formData: any) {
  return httpClient.post(`/gbs/${localStorage.getItem('cid')}/brands/upload`, formData)
}

function search(page: any) {
  return httpClient.post(`/gbs/${localStorage.getItem('cid')}/search?page=${page}&size=20&sort=startDateTime,desc`, {}).then((response: any) => response.data)
}

function searchPosition() {
  return httpClient.get(`/gbs/${localStorage.getItem('cid')}/brands/archive?size=1000`).then((response) => response.data)
}

export default {
  stealArticleInfo,
  getRequestById,
  getStealTemplate,
  search,
  stealArticles,
  uploadPricePosition,
  getPositionTemplate,
  searchPosition,
}
