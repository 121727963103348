
import { defineComponent } from 'vue';
import BaseTable from '@/components/ui/BaseTable.vue';
import { ConvertDateWithTime } from '@/components/converters/date';
import { GbsDownloadingStatuses, GbsDownloadingStatusesType } from '@/components/types/enums';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default defineComponent({
  name: 'GbsTableArchiveArticlesView',
  components: {
    BaseTable,
    BaseIcon
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    pageable: {
      type: Object,
      default: () => ({
        pageNumber: 1,
        totalPages: 10
      })
    }
  },
  data: () => ({
  }),
  computed: {
    isFinishedStatus() {
      return (item: any) => {
        const status = item.status as GbsDownloadingStatusesType
        return GbsDownloadingStatuses[status] === GbsDownloadingStatuses.FINISHED
      }
    },
    getFileLink() {
      return (item: any) => {
        const fileName = item.link.split('\\').slice(-1)
        const cid = localStorage.getItem('cid')

        return `${process.env.VUE_APP_URL_FILES}${cid}/gbs/${fileName}`
      }
    },
    headers(): any {
      return [
        {
          title: 'Автор',
          content: (item: any) => item.user
        },
        {
          title: 'Дата',
          content: (item: any) => ConvertDateWithTime(item.startDateTime)
        },
        {
          title: 'Статус загрузки',
          content: (item: any) => {
            const status = item.status as GbsDownloadingStatusesType
            return GbsDownloadingStatuses[status]
          }
        },
        {
          title: 'Скачать файл',
          content: () => 'Недоступен для скачивания'
        },
      ]
    },
  },
  methods: {
    previous() {
      if (!(this.pageable.pageNumber === 0)) {
        this.$emit('changePage', this.pageable.pageNumber - 1)
      }
    },
    next () {
      if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
        this.$emit('changePage', this.pageable.pageNumber + 1)
      }
    },
  },
  mounted() {
    console.log(this.data)
  }
})
