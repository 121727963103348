
import { defineComponent } from 'vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import gbs from '@/services/gbs';
import BaseIcon from '@/components/ui/BaseIcon.vue';

export default defineComponent({
  name: 'GbsUploadArticlesFromListView',
  components: {
    BaseIcon
  },
  data: () => ({
    formDataFile: null as any,
  }),
  computed: {
    getLinkForDownloadTemplate() {
      return gbs.getStealTemplate()
    }
  },
  methods: {
    parseTable(e: any) {
      let file = e.target.files;
      const formData = new FormData();
      formData.append('file', file[0]);
      this.formDataFile = formData
    },
    async sendFile() {
      try {
        await gbs.stealArticleInfo(this.formDataFile)
        this.formDataFile = null
        const input = document.querySelector('input[type=file]') as any
        input.value = '';
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
        this.$emit('updateArchiveArticles')
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
  },
})
