
import { defineComponent } from 'vue';
import BaseTabs from '@/components/ui/BaseTabs.vue';
import GbsUploadArticlesFromListView
  from '@/components/price/GbsPriceViews/GbsUploadArticlesFromListView.vue';
import GbsTableArchiveArticlesView
  from '@/components/price/GbsPriceViews/GbsTableArchiveArticlesView.vue';
import gbs from '@/services/gbs';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import GbsUploadArticlesPositionView from '@/components/price/GbsPriceViews/GbsUploadArticlesPositionView.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import articles from '@/services/articles';
import debounce from '@/components/debounce';
import SmallSpinner from '@/components/SmallSpinner.vue';

export default defineComponent({
  name: 'GbsPage',
  components: {
    SmallSpinner,
    GbsUploadArticlesPositionView,
    GbsTableArchiveArticlesView,
    BaseTabs,
    GbsUploadArticlesFromListView,
    SearchDropdown,
    BaseIcon
  },
  data: () => ({
    isLoading: false as boolean,
    selectedArticles: [] as any,
    archiveArticlesPageable: null as any,
    archiveArticles: [] as any,
    tabs: [
      { name: 'Проценка по артикулам', query: 'price-articles'},
      { name: 'Проценка по списку артикулов', query: 'price-list-articles'},
      { name: 'Загрузка артикулов из списка', query: 'upload-list-articles'},
      { name: 'Загрузить позиционирование', query: 'positions'},
      { name: 'Архив', query: 'archive'}
    ]
  }),
  computed: {
    lastArticle(): any {
      const article = this.selectedArticles[this.selectedArticles.length - 1]
      return article && article.article
        ? article.article
        : ''
    }
  },
  methods: {
    async saveArticles() {
      const ids = this.selectedArticles.map((article: any) => article.article)

      try {
        await gbs.stealArticles(ids)
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
        this.selectedArticles = []
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    setArticle(article: any) {
      if (article) {
        this.selectedArticles.push(article)
      }
    },
    unsetArticle(articleId: number) {
      this.selectedArticles = this.selectedArticles.filter((article: any) => {
        const field = article && article.id ? 'id' : 'articleId'

        return article[field] !== articleId
      })
    },
    setManyArticles(event: any) {
      const listArticles = event.target.value.split('\n')
      this.isLoading = true

      listArticles.map(async (article: any) => {
        if (article.length) {
          try {
            const isArticleAlreadyExist = this.selectedArticles.findIndex((selectedArticle: any) => {
              return selectedArticle.article === article
            }) >= 0

            if (!isArticleAlreadyExist) {
              const articleName = article.replace(/ /g, '')
              const foundedArticles = await articles.findArticleByName(articleName)
              const existedArticle = foundedArticles.find((el: any) => {
                const currentArticle = el.article.article.toUpperCase()
                const replacedArticle = article.replace(/ /g, '').toUpperCase()

                return currentArticle === replacedArticle
              })

              if (existedArticle) {
                this.selectedArticles.push(existedArticle.article)
              }
            }
          } catch (error) {
            console.error(error)
          } finally {
            this.isLoading = false
          }
        }
      })
      // console.log(items)
    },
    async getArchiveArticles(page = 0) {
      const { content, totalPages, pageable } = await gbs.search(page)
      this.archiveArticles = content
      this.archiveArticlesPageable = {
        pageNumber: pageable.pageNumber,
        totalPages
      }
    }
  },
  setup() {
    return { ...debounce() }
  },
  mounted() {
    this.getArchiveArticles()
  }
})
